// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit";

import { handleAbility } from "../views/pages/authentication/handle-ability";
export const authSlice = createSlice({
  name: "authentication",
  initialState: {
    account: null,
    username: null,
    isAuthenticated: false,
    role: null,
  },
  reducers: {
    handleLogin: (state, action) => {
      const role = action.payload.role;
      let account = action.payload.account;
      const ability = handleAbility(role);
      account = { ...account, ability };
      state.account = action.payload.account;
      state.username = action.payload.username;
      state.isAuthenticated = action.payload.isAuthenticated;
      localStorage.setItem("account", JSON.stringify(account));
      localStorage.setItem("username", action.payload.username);
      localStorage.setItem("isAuthenticated", action.payload.isAuthenticated);
      localStorage.setItem("role", role);
    },
    handleLogout: (state) => {
      state.account = null;
      state.username = null;
      state.isAuthenticated = false;
      localStorage.removeItem("account");
      localStorage.removeItem("username");
      localStorage.removeItem("isAuthenticated");
      localStorage.removeItem("role");
    },
  },
});

export const { handleLogin, handleLogout } = authSlice.actions;

export default authSlice.reducer;
