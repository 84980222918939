export const handleAbility = (role) => {
  return role === "admin"
    ? [
        {
          action: "manage",
          subject: "all",
        },
        {
          action: "manage",
          subject: "DSH",
        },
        {
          action: "manage",
          subject: "ADM",
        },
      ]
    : [
        {
          action: "manage",
          subject: "DSH",
        },
      ];
};
