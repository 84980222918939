// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiConfig } from "@src/auth/authConfig";
import { acquireToken } from "@src/auth/authProvider";

// ** Axios Imports
import axios from "axios";

export const getReports = createAsyncThunk(
  "appCenters/getReports",
  async (data) => {
    const uri = `${apiConfig.resourceUri}reports/reportHistory?currentPage=${data.page}&pageSize=${data.pageSize}`;
    const tokenResponse = await acquireToken();
    const response = await axios.get(uri, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${tokenResponse.accessToken}`,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  }
);

export const getReport = createAsyncThunk(
  "appCenters/getReport",
  async (id) => {
    const uri = `${apiConfig.resourceUri}reports/getReport/${id}`;
    const tokenResponse = await acquireToken();
    const response = await axios.get(uri, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${tokenResponse.accessToken}`,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  }
);

export const appReportsSlice = createSlice({
  name: "appReports",
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedUser: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getReports.fulfilled, (state, action) => {
      state.reports = action.payload.data;
    });
    builder.addCase(getReport.fulfilled, (state, action) => {
      state.report = {
        data: action.payload.data,
        success: action.meta.requestStatus,
      };
    });
  },
});

export default appReportsSlice.reducer;
