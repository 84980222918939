import { PublicClientApplication } from "@azure/msal-browser"
import { authConfig, tokenRequest, silentRequest } from './authConfig'

const msalApp = new PublicClientApplication(authConfig)

export const acquireToken = async () => {

    silentRequest.account = msalApp.getAccountByUsername(localStorage.getItem('username'))

    return msalApp.acquireTokenSilent(silentRequest).catch(error => {
        console.warn("silent token acquisition fails. acquiring token using interactive method")
        if (error) {
            tokenRequest.account = msalApp.getAccountByUsername(localStorage.getItem('username'))
            return msalApp.acquireTokenPopup(tokenRequest)
        } else {
            console.warn(error)
        }
    })
}