// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { INFO_MODAL_TYPES } from "..";
const infoModalState = {
  open: false,
  type: "",
  icon: "",
  title: "",
  message: "",
  onClose: null,
  cancelButtonText: "",
  acceptButtonText: "",
  acceptAction: null,
};
export const toggleInfoModal = createAsyncThunk(
  "appModal/toggleInfoModal",
  async (data) => {
    return data;
  }
);

export const appInfoModalSlice = createSlice({
  name: "appModal",
  initialState: infoModalState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      toggleInfoModal.fulfilled,
      (state = infoModalState, action) => {
        return {
          ...state,
          open: action.payload.open || false,
          type: action.payload.type || INFO_MODAL_TYPES.error.value,
          icon: action.payload.icon || "",
          title: action.payload.title || "",
          message: action.payload.message || "",
          onClose: action.payload.onClose || null,
          cancelButtonText: action.payload.cancelButtonText || "",
          acceptButtonText: action.payload.acceptButtonText || "GOT_IT",
          acceptAction: action.payload.acceptAction || null,
          acceptIcon: action.payload.acceptIcon || null,
        };
      }
    );
  },
});

export default appInfoModalSlice.reducer;
