export const authConfig = {
    auth: {
        clientId: "f9c25f1e-0e82-4986-8ac4-a2d0928f9e5c",
        authority: "https://login.microsoftonline.com/vrijclbnetwerk.onmicrosoft.com",
        redirectUri: "/"
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: false
    }
}

export const apiConfig = {
    resourceUri: process.env.REACT_APP_CLBCRM_APIENDPOINT,
    resourceScopes: ["api://54a38b35-ed83-4089-ac83-ca8def920c30/.default"]
}


export const loginRequest = {
    scopes: ["openid", "profile", "offline_access", ...apiConfig.resourceScopes]
}

export const tokenRequest = {
    scopes: [...apiConfig.resourceScopes]
}

export const silentRequest = {
    scopes: ["openid", "profile", ...apiConfig.resourceScopes]
}