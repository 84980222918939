import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import FeatherIcon from "react-feather";
import { INFO_MODAL_ID } from "./info-modal-constants";
import { useTranslation } from "react-i18next";
import { toggleInfoModal } from "./store";

import "./info-modal.scss";

export const INFO_MODAL_TYPES = {
  error: {
    value: "error",
    title: "COMMON.ERROR",
    icon: "x-circle",
  },
  success: {
    value: "success",
    title: "COMMON.SUCCESS",
    icon: "check-circle",
  },
  info: {
    value: "info",
    title: "COMMON.INFO",
    icon: "info",
  },
  warning: {
    value: "warning",
    title: "COMMON.WARNING",
    icon: "alert-triangle",
  },
};

const InfoModal = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    open,
    type,
    title,
    message,
    onClose,
    cancelButtonText,
    acceptButtonText,
    acceptAction,
  } = useSelector((store) => store.modal);

  const handleClose = () => {
    if (onClose) {
      onClose();
    }

    dispatch(toggleInfoModal(false));
  };

  const handleAcceptButtonClick = () => {
    if (acceptAction) {
      acceptAction();
    }

    handleClose();
  };

  const getTitle = () => {
    let result = title;

    if (!result) {
      result =
        type && INFO_MODAL_TYPES[type] && INFO_MODAL_TYPES[type].title
          ? INFO_MODAL_TYPES[type].title
          : null;
    }

    return result;
  };

  useEffect(() => {
    const listener = (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        handleAcceptButtonClick();
      }
    };

    if (open) {
      document.addEventListener("keydown", listener);
    } else {
      document.removeEventListener("keydown", listener);
    }

    return () => {
      document.removeEventListener("keydown", listener);
    };
    // eslint-disable-next-line
  }, [open]);

  if (!open) {
    return null;
  }

  return (
    <div className="modal -redux" id={INFO_MODAL_ID}>
      <div className="modal-body">
        <div className="modal-header justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            {/* {renderIcon ? (
              <FeatherIcon
                className={`icon-type ${type ? `-${type}` : ""} me-2`}
                icon={renderIcon}
                size={iconSize}
              />
            ) : null} */}
            <span className="app-text -white -large jus me-2">
              {t(getTitle())}
            </span>
          </div>
          {/* <FeatherIcon
            className="justify-self-end"
            icon="x"
            size={iconSize}
            onClick={handleClose}
          /> */}
        </div>
        <div className="modal-message app-text -white">{t(message)}</div>
        <div className="modal-buttons d-flex justify-content-end align-items-center">
          {cancelButtonText ? (
            <button
              type="button"
              className="app-button -gray me-2"
              onClick={handleClose}
              ref={() => {
                // button && button.focus();
              }}
            >
              {t(cancelButtonText)}
            </button>
          ) : null}
          <button
            className="app-button -primary"
            type="button"
            onClick={handleAcceptButtonClick}
            ref={() => {
              // if (!cancelButtonText) button && button.focus();
            }}
          >
            {/* {acceptIcon ? (
              <FeatherIcon className="justify-self-end" icon={acceptIcon} />
            ) : null} */}
            {t(acceptButtonText)}
          </button>
        </div>
      </div>
    </div>
  );
};

export default InfoModal;
