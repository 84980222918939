// ** Reducers Imports
import navbar from "./navbar";
import layout from "./layout";
import auth from "./authentication";
import modal from "@src/@core/components/info-modal/store";
import calendar from "@src/views/apps/calendar/store";
import centers from "@src/views/apps/panel/center-list/store";
import topics from "@src/views/apps/panel/topic-list/store";
import keyprocesses from "@src/views/apps/panel/key-process-list/store";
import reports from "@src/views/apps/report-history/store";

const rootReducer = {
  auth,
  navbar,
  layout,
  modal,
  calendar,
  centers,
  topics,
  keyprocesses,
  reports,
};

export default rootReducer;
