// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiConfig } from "@src/auth/authConfig";
import { acquireToken } from "@src/auth/authProvider";

// ** Axios Imports
import axios from "axios";

export const getTopics = createAsyncThunk(
  "appTopics/getTopics",
  async (data) => {
    const uri = `${apiConfig.resourceUri}topics/getAllTopics?currentPage=${data.page}&pageSize=${data.pageSize}`;
    const tokenResponse = await acquireToken();
    const response = await axios.get(uri, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${tokenResponse.accessToken}`,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  }
);

export const getTopic = createAsyncThunk("appTopics/getTopic", async (id) => {
  const uri = `${apiConfig.resourceUri}topics/getTopic/${id}`;
  const tokenResponse = await acquireToken();
  const response = await axios.get(uri, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${tokenResponse.accessToken}`,
      "Content-Type": "application/json",
    },
  });
  return response.data;
});

export const searchTopics = createAsyncThunk(
  "appTopics/searchTopics",
  async (data) => {
    const uri = `${apiConfig.resourceUri}topics/search/${data.term}/${data.page}/${data.pageSize}`;
    const tokenResponse = await acquireToken();
    const response = await axios.get(uri, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${tokenResponse.accessToken}`,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  }
);

export const saveTopic = createAsyncThunk(
  "appTopics/addTopic",
  async (topic) => {
    const uri = `${apiConfig.resourceUri}topics/`;
    const tokenResponse = await acquireToken();
    const response = await axios.post(uri, topic, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${tokenResponse.accessToken}`,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  }
);

export const removeTopic = createAsyncThunk(
  "appTopics/removeTopic",
  async (id) => {
    const uri = `${apiConfig.resourceUri}topics/${id}`;
    const tokenResponse = await acquireToken();
    const response = await axios.delete(uri, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${tokenResponse.accessToken}`,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  }
);

export const appTopicsSlice = createSlice({
  name: "appTopics",
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedUser: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTopics.fulfilled, (state, action) => {
        state.topics = action.payload.data;
      })
      .addCase(getTopic.fulfilled, (state, action) => {
        state.topic = {
          data: action.payload.data,
          success: action.meta.requestStatus,
        };
      })
      .addCase(saveTopic.fulfilled, (state, action) => {
        state.topic = {
          data: action.payload,
          success: action.meta.requestStatus,
        };
      })
      .addCase(removeTopic.fulfilled, (state, action) => {
        state.topic = {
          data: action.payload,
          success: action.meta.requestStatus,
        };
      })
      .addCase(searchTopics.fulfilled, (state, action) => {
        state.topics = action.payload.data;
      });
  },
});

export default appTopicsSlice.reducer;
