import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { apiConfig } from "@src/auth/authConfig";

import moment from "moment";

import { acquireToken } from "@src/auth/authProvider";

import axios from "axios";

export const fetchActivities = createAsyncThunk(
  "appCalenar/fetchActivities",
  async (filterData) => {
    const uri = `${apiConfig.resourceUri}activities/`;
    const tokenResponse = await acquireToken();
    const response = await axios.post(uri, filterData, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${tokenResponse.accessToken}`,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  }
);

export const addActivity = createAsyncThunk(
  "appCalendar/addActivity",
  async (dataObject, { dispatch }) => {
    const uri = `${apiConfig.resourceUri}activities/addActivity`;
    const tokenResponse = await acquireToken();
    const response = await axios.post(uri, dataObject.addObject, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${tokenResponse.accessToken}`,
        "Content-Type": "application/json",
      },
    });
    dispatch(fetchActivities(dataObject.filterData));
    return response.data;
  }
);

export const updateActivity = createAsyncThunk(
  "appCalendar/updateActivity",
  async (dataObject, { dispatch }) => {
    const uri = `${apiConfig.resourceUri}activities/patchActivity`;
    const tokenResponse = await acquireToken();
    const response = await axios.patch(uri, dataObject.patchObject, {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${tokenResponse.accessToken}`,
        "Content-Type": "application/json",
      },
    });
    dispatch(fetchActivities(dataObject.filterData));
    return response.data;
  }
);

export const deleteActivity = createAsyncThunk(
  "appCalendar/deleteActivity",
  async (dataObject, { dispatch }) => {
    const uri = `${apiConfig.resourceUri}activities/deleteActivity`;
    const tokenResponse = await acquireToken();
    const response = await axios.post(uri, dataObject.deleteObject, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${tokenResponse.accessToken}`,
        "Content-Type": "application/json",
      },
    });
    dispatch(fetchActivities(dataObject.filterData));
    return response.data;
  }
);

export const createReport = createAsyncThunk(
  "appCalenar/createReport",
  async (filterData) => {
    const uri = `${apiConfig.resourceUri}reports/`;
    const tokenResponse = await acquireToken();
    axios
      .post(uri, filterData, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${tokenResponse.accessToken}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const url = decodeURIComponent(response.data.data.reportUrl);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", response.data.data.reportName);
        document.body.appendChild(link);
        link.click();
      });
  }
);

export const getEmployees = createAsyncThunk("get/employees", async () => {
  const uri = `${apiConfig.resourceUri}users`;
  const tokenResponse = await acquireToken();
  return axios.get(uri, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${tokenResponse.accessToken}`,
      "Content-Type": "application/json",
    },
  });
});

export const getKeyProcess = createAsyncThunk("get/keyprocess", async () => {
  const uri = `${apiConfig.resourceUri}keyprocess/filter`;
  const tokenResponse = await acquireToken();
  return axios.get(uri, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${tokenResponse.accessToken}`,
      "Content-Type": "application/json",
    },
  });
});

export const getTopics = createAsyncThunk("get/topics", async () => {
  const uri = `${apiConfig.resourceUri}topics/filter`;
  const tokenResponse = await acquireToken();
  return axios.get(uri, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${tokenResponse.accessToken}`,
      "Content-Type": "application/json",
    },
  });
});

export const getCenters = createAsyncThunk("get/centers", async () => {
  const uri = `${apiConfig.resourceUri}centers/filter`;
  const tokenResponse = await acquireToken();
  return axios.get(uri, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${tokenResponse.accessToken}`,
      "Content-Type": "application/json",
    },
  });
});

export const appCalendarSlice = createSlice({
  name: "appCalendar",
  initialState: {
    events: [],
    selectedEvent: {},
  },
  reducers: {
    selectEvent: (state, action) => {
      state.selectedEvent = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchActivities.fulfilled, (state, action) => {
        const allEvents = [];
        action.payload.data.forEach((event) => {
          const currentUtcOffset = moment.parseZone(event.start).utcOffset();
          allEvents.push({
            id: event.id,
            title: event.title,
            start: moment(event.start).utc(currentUtcOffset).format(),
            end: moment(event.end).utc(currentUtcOffset).format(),
            textColor: event.textColor,
            backgroundColor: event.backgroundColor,
            borderColor: event.borderColor,
            extendedProps: event.extendedProps,
          });
        });
        state.events = allEvents;
      })
      .addCase(getEmployees.fulfilled, (state, action) => {
        state.employees = action.payload.data;
      })
      .addCase(getTopics.fulfilled, (state, action) => {
        state.topics = action.payload.data;
      })
      .addCase(getKeyProcess.fulfilled, (state, action) => {
        state.keyprocess = action.payload.data;
      })
      .addCase(getCenters.fulfilled, (state, action) => {
        state.centers = action.payload.data;
      });
  },
});

export const { selectEvent } = appCalendarSlice.actions;

export default appCalendarSlice.reducer;
