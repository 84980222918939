// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiConfig } from "@src/auth/authConfig";
import { acquireToken } from "@src/auth/authProvider";

// ** Axios Imports
import axios from "axios";

export const getCenters = createAsyncThunk(
  "appCenters/getCenters",
  async (data) => {
    const uri = `${apiConfig.resourceUri}centers/getAllCenters?currentPage=${data.page}&pageSize=${data.pageSize}`;
    const tokenResponse = await acquireToken();
    const response = await axios.get(uri, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${tokenResponse.accessToken}`,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  }
);

export const getCenter = createAsyncThunk(
  "appCenters/getCenter",
  async (id) => {
    const uri = `${apiConfig.resourceUri}centers/getCenter/${id}`;
    const tokenResponse = await acquireToken();
    const response = await axios.get(uri, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${tokenResponse.accessToken}`,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  }
);

export const searchCenters = createAsyncThunk(
  "appCenters/searchCenters",
  async (data) => {
    const uri = `${apiConfig.resourceUri}centers/search/${data.term}/${data.page}/${data.pageSize}`;
    const tokenResponse = await acquireToken();
    const response = await axios.get(uri, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${tokenResponse.accessToken}`,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  }
);

export const saveCenter = createAsyncThunk(
  "appCenters/addCenter",
  async (center) => {
    const uri = `${apiConfig.resourceUri}centers/`;
    const tokenResponse = await acquireToken();
    const response = await axios.post(uri, center, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${tokenResponse.accessToken}`,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  }
);

export const removeCenter = createAsyncThunk(
  "appCenters/removeCenter",
  async (id) => {
    const uri = `${apiConfig.resourceUri}centers/${id}`;
    const tokenResponse = await acquireToken();
    const response = await axios.delete(uri, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${tokenResponse.accessToken}`,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  }
);

export const appCentersSlice = createSlice({
  name: "appCenters",
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedUser: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCenters.fulfilled, (state, action) => {
        state.centers = action.payload.data;
      })
      .addCase(getCenter.fulfilled, (state, action) => {
        state.center = {
          data: action.payload.data,
          success: action.meta.requestStatus,
        };
      })
      .addCase(saveCenter.fulfilled, (state, action) => {
        state.center = {
          data: action.payload,
          success: action.meta.requestStatus,
        };
      })
      .addCase(removeCenter.fulfilled, (state, action) => {
        state.center = {
          data: action.payload,
          success: action.meta.requestStatus,
        };
      })
      .addCase(searchCenters.fulfilled, (state, action) => {
        state.centers = action.payload.data;
      });
  },
});

export default appCentersSlice.reducer;
