// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiConfig } from "@src/auth/authConfig";
import { acquireToken } from "@src/auth/authProvider";

// ** Axios Imports
import axios from "axios";

export const getKeyProcesses = createAsyncThunk(
  "appCenters/KeyProcesses",
  async (data) => {
    const uri = `${apiConfig.resourceUri}keyProcess/getAllKeyProcesses?currentPage=${data.page}&pageSize=${data.pageSize}`;
    const tokenResponse = await acquireToken();
    const response = await axios.get(uri, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${tokenResponse.accessToken}`,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  }
);

export const getKeyProcess = createAsyncThunk(
  "appCenters/getKeyProcess",
  async (id) => {
    const uri = `${apiConfig.resourceUri}keyProcess/getKeyProcess/${id}`;
    const tokenResponse = await acquireToken();
    const response = await axios.get(uri, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${tokenResponse.accessToken}`,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  }
);

export const searchKeyProcesses = createAsyncThunk(
  "appCenters/searchKeyProcesses",
  async (data) => {
    const uri = `${apiConfig.resourceUri}keyProcess/search/${data.term}/${data.page}/${data.pageSize}`;
    const tokenResponse = await acquireToken();
    const response = await axios.get(uri, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${tokenResponse.accessToken}`,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  }
);

export const saveKeyProcess = createAsyncThunk(
  "appCenters/addKeyProcess",
  async (keyProcess) => {
    const uri = `${apiConfig.resourceUri}keyProcess/`;
    const tokenResponse = await acquireToken();
    const response = await axios.post(uri, keyProcess, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${tokenResponse.accessToken}`,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  }
);

export const removeKeyProcess = createAsyncThunk(
  "appCenters/removeKeyProcess",
  async (id) => {
    const uri = `${apiConfig.resourceUri}keyProcess/${id}`;
    const tokenResponse = await acquireToken();
    const response = await axios.delete(uri, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${tokenResponse.accessToken}`,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  }
);

export const appKeyProcessesSlice = createSlice({
  name: "appKeyProcesses",
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedUser: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getKeyProcesses.fulfilled, (state, action) => {
        state.keyprocesses = action.payload.data;
      })
      .addCase(getKeyProcess.fulfilled, (state, action) => {
        state.keyprocess = {
          data: action.payload.data,
          success: action.meta.requestStatus,
        };
      })
      .addCase(saveKeyProcess.fulfilled, (state, action) => {
        state.keyprocess = {
          data: action.payload,
          success: action.meta.requestStatus,
        };
      })
      .addCase(removeKeyProcess.fulfilled, (state, action) => {
        state.keyprocess = {
          data: action.payload,
          success: action.meta.requestStatus,
        };
      })
      .addCase(searchKeyProcesses.fulfilled, (state, action) => {
        state.keyprocesses = action.payload.data;
      });
  },
});

export default appKeyProcessesSlice.reducer;
